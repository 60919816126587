@import "./res.scss";
.bigChartBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    @include xxl {
      font-size: 24px;
    }
  }
  .chart {
    width: 100%;
    height: 300px;
  }
}
