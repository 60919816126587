body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  min-height: 80vh;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}

.product-name {
  text-decoration: none;
}

.product-name:hover {
  text-decoration: underline;
}

.btn-block:hover {
  text-decoration: underline;
}

.btn-full {
  max-width: 100%;
}

.custom {
  color: #fff !important;
}

.custom:hover {
  color: #000;
}

.custom1 {
  color: #fff !important;
}
