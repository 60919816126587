.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding-top: 4%;
  margin: 0;
}
.food-img {
  width: 60%;
  height: 35rem;
  object-fit: cover;
  border-radius: 10px;
}
.food-logo {
  object-fit: contain;
  border-radius: 10px;
  width: 100%;
  height: 5rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
}
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}
input,
select,
textarea {
  background-color: transparent;
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}
body {
  min-height: 100vh;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  margin: 0;
}
.container-item {
  margin-left: 20px;
  width: 100%;
  height: 100%;
}

.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title {
  text-align: center;
}
.w800 {
  font-weight: 800;
}
.contain-title {
  font-size: 14px;
}
.contain-mid-item {
  margin-bottom: 10px;
}
.input-email {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 10px;
  color: black;
  padding-bottom: 10px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #b3b3b3;
}
.contain-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text {
  text-align: center;
  padding-top: 10px;
  margin-left: 20px;
}
.button {
  background-color: #fc8019;
  color: #ffffff;
  padding: 15px 0;
  width: 100%;
  border-radius: 10px;
}
.button:hover {
  background-color: #df6703;
}
.button-text {
  color: #000000;
}
.contain-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.button-login:hover {
  background-color: gray;
}
.button-login {
  padding: 10px 20px;
  border: solid 0.4px gray;
  border-radius: 5px;
}
.signup {
  color: #fc8019;
}

.fz18 {
  font-size: 18px;
}

.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
  color: #3b5998;
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  width: 3.4375rem;
  line-height: 3.375rem;
  border-radius: 50px;
}

.logup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(252, 128, 25, 0.1);
}
.login-contain {
  width: 35%;
  height: 90%;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-login {
  margin: 0 auto;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0;
}
.mt-10 {
  margin-top: 10px;
}

.login-top {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
}

.navbar {
  align-items: center;
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  align-items: flex-start;
  min-width: 60%;
  margin: 0 auto;
}

.hover:hover {
  background-color: #df6703 !important;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.carousel-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}

.w50 {
  width: 50%;
  object-fit: contain;
}
