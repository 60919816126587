.barChartBox {
  width: 100%;
  height: 100%;
  h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .chart {
  }
}
